@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    background: #0f172a;
    font-family:
        ui-sans-serif,
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        Roboto,
        'Helvetica Neue',
        Arial,
        'Noto Sans',
        sans-serif,
        'Apple Color Emoji',
        'Segoe UI Emoji',
        'Segoe UI Symbol',
        'Noto Color Emoji';
}

html,
body,
#root {
    height: 100%;
}
